import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../hooks/useWeb3";
import environment from "../utils/Enviroment";
import { getdopTokenContract } from "../utils/contractHelpers";

export const ApproveAllow =()=>{
    const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = environment?.Doptoken;    
    const contract = getdopTokenContract(contractAddress,web3);
    let value = "99999999999999999999999999999";
    
    const ApproveTokenAllow = useCallback (
        async() =>{
            try {
                // const contract = getClaimingContract(contractAddress, web3);
                const gas = await contract.methods.approve(environment?.StackContact,value)
                  ?.estimateGas({ from: account });
          
                const gasPrice = await web3.eth.getGasPrice();
                const gasPriceWithBuffer = parseInt(gasPrice) + 3000000000;

                const balance = await contract.methods.approve(environment?.StackContact,value).send({from:account, gas: gas, gasPrice: gasPriceWithBuffer})
                // console.log('eeeeeeeeee', balance);
                return balance
            } catch (error) {
                console.log('9999999', error)
                throw error;
            }
        },[contract]
    );
    return { ApproveTokenAllow: ApproveTokenAllow };
}
export default ApproveAllow;
