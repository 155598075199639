
import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../hooks/useWeb3";
import environment from "../utils/Enviroment";
import { getdopTokenContract } from "../utils/contractHelpers";

export const TomiAllowance =()=>{
    const {account} = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = environment?.Doptoken;
    const contract = getdopTokenContract(contractAddress,web3);

    // console.log("account here",account)
    const AllowanceTomi = useCallback (
        async() =>{
            // console.log("here is")
            // let val = web3.utils.toWei(spendBnb.toString(), "ether");
            try {
                const balance = await contract.methods.allowance(account,environment?.StackContact).call()
                let bal = web3.utils.fromWei(balance, "ether")
                // console.log("allown", bal)
                return bal;
            } catch (error) {
                console.log('9999999', error)
                throw error;
            }
        },[contract]
    );
    return { AllowanceTomi: AllowanceTomi };
}
export default TomiAllowance;