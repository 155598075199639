import React, { useState, useEffect } from "react";

const CountdownTimer = ({ setTimeIsUp, targetEpochTime }) => {
  const calculateTimeLeft = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = targetEpochTime - currentTime;
    return Math.max(timeLeft, 0);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();

      setTimeLeft(newTimeLeft);
      if (newTimeLeft <= 0) {
        clearInterval(interval);
        setTimeIsUp(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetEpochTime, setTimeIsUp]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return ` ${days}D : ${hours}H : ${minutes}M : ${seconds}S `;
  };

  return <div>{formatTime(timeLeft)}</div>;
};

export default CountdownTimer;
